<template>
  <v-app class>
    <div>
     <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>Pending Salary Component
            </h1>
          </div>

          <v-data-table loading-text="Loading Pending Salary Periods... Please wait"
                      :loading="!pendingSalaryComponents.length"
              :headers= 'headers'
              :items="pendingSalaryComponents"
             >
                <template v-slot:item.dateAdded="{ item }" >
                  {{item.dateAdded | formatDate}}
              </template>

                <template v-slot:item.salaryComponentType="{ item }" >
                  <div v-if="item.salaryComponentType == 2">Allowance</div>
                  <div v-if="item.salaryComponentType == 1">ConsolidatedSalary</div>
                  <div v-if="item.salaryComponentType == 3">Benefit</div>
                  <div v-if="item.salaryComponentType == 4">Statutory</div>
              </template>
                <template v-slot:item.actions="{ item }" >
                    <div class="d-flex">
                        <Button :btnType="'Submit'" :label="'View'" @onClick="viewModal(item)" class="mr-2"/>
                        <Button :btnType="'Submit'" :label="'Approve'" @onClick="openConfiModal(item)" class="mr-2"/>
                        <Button :btnType="'Cancel'" :label="'Reject'" @onClick="reject(item)" />

                    </div>
              </template>

             </v-data-table>
              <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isLoading" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="approve"/>
        </v-container>
      </div>
    </div>

    <!-- Dialog for edit  -->
          <Dialog ref="editModal" :title="'View'" :width="600">
                <v-form>
                  <v-row>
                    <v-col >
                      <TextField v-model="formData.description" :disable="true" :label="'Name'" />
                    </v-col>
                    <v-col >
                      <TextField v-model="formData.SalaryComponentFrequency" :disable="true" :label="'Frequency'" />
                    </v-col>
                    <v-col>
                      <TextField v-model="formData.salaryComponentType" :disable="true" :label="'Salary Component Type'" />
                    </v-col>
                    <v-col >
                      <TextField v-model="formData.markup" :disable="true" :type="'number'"
                      :label="'Markup'" />
                    </v-col>
                    <v-col dense>
                      <v-radio-group disabled v-model="formData.isTimeSheetDriven" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Timesheet ?">
                        <v-radio label="Yes" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>

                      <v-col cols="6" v-if="formData.isTimeSheetDriven === true">
                       <!-- <v-select v-model="formData.TimesheetItemType" :items="TimesheetItemType" item-value="id" item-text="name" label="Timesheet Item type" return-object outlined dense/> -->
                       <TextField v-model="formData.TimesheetItemType" :disable="true" :label="'Timesheet Item type'" />
                    </v-col>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" v-if="formData.TimesheetItemType.id ===  1 ">
                       <TextField v-model="formData.overtimeType" :disable="true" :label="'Overtime Type'" />
                    </v-col>

                    <v-col>
                      <v-radio-group disabled v-if="formData.isAllowance === false" v-model="formData.isBasic" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="CMS ?">
                        <v-radio label="Yes" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col>
                      <v-radio-group disabled v-model="formData.isFlat" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Fixed Amount ?">
                        <v-radio label="Yes" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col>
                      <v-radio-group v-model="formData.isBaseOnBasic" disabled :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label=" Based on CMS ?">
                        <v-radio label="Yes" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col>
                      <v-radio-group v-model="formData.isTaxable" disabled :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label=" Taxable ?">
                        <v-radio label="Yes" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
            </v-row>
            </v-form>
            <template v-slot:footer>
                <Button
                :btnType="'Cancel'"
                :label="'Close'"
                @onClick="cancel('editModal')"
              />
            </template>
          </Dialog>
  </v-app>
</template>
<script>
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import Dialog from '@/components/ui/Dialog.vue'
import TextField from '@/components/ui/form/TextField.vue'
import {
  APPROVE_SALARY,
  FETCH_PENDING_SALARY
} from '@/store/action-type'

export default {
  components: {
    Button,
    ConfirmationDialog,
    Dialog,
    TextField
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      alertType: '',
      item: null,
      isLoading: false,
      dialog: false,
      salaryComponentType: [{ id: 1, name: 'ConsolidatedSalary' }, { id: 2, name: 'Allowance' }, { id: 3, name: 'Benefit' }, { id: 4, name: 'Statutory' }, { id: 5, name: 'Deduction' }],
      TimesheetItemType: [{ id: 1, name: 'Overtime' }, { id: 2, name: 'Shift' }],
      OverTimeType: [{ id: 1, name: 'weekday' }, { id: 2, name: 'weekend' }],
      formData: {
        id: '',
        description: '',
        isTimeSheetDriven: null,
        isFlat: null,
        TimesheetItemType: 0,
        OverTimeType: 0,
        isBaseOnBasic: null,
        SalaryComponentFrequency: '',
        isTaxable: null,
        markup: 0,
        salaryComponentType: ''
      },
      headers: [
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Salary Component Type',
          value: 'salaryComponentType'
        },
        {
          text: 'Frequency',
          value: 'salaryComponentFrequency.description'
        },
        {
          text: 'Markup',
          value: 'markup'
        },
        {
          text: 'Added By',
          value: 'actionedBy'
        },
        {
          text: 'Action',
          value: 'action'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    pendingSalaryComponents () {
      return this.$store.getters.pendingSalaryComponents
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    viewModal (item) {
      this.formData.id = item.id
      this.$refs.editModal.openModal()
      this.formData.isTaxable = item.isTaxable
      this.formData.isBaseOnBasic = item.isBasedOnBasic
      this.formData.isFlat = item.isFlat
      this.formData.isTimeSheetDriven = item.isTimeSheetDriven
      this.formData.description = item.description
      this.formData.SalaryComponentFrequency = item.salaryComponentFrequency.description
      const SalaryComponent = this.salaryComponentType.filter(sal => sal.id === item.salaryComponentType)
      if (item.timesheetItemType !== 0) {
        const Timesheettype = this.TimesheetItemType.filter(timeSheet => timeSheet.id === item.timesheetItemType)
        this.formData.TimesheetItemType = Timesheettype[0].name || ''
      }
      if (item.overtimeType !== 0) {
        const overTime = this.OverTimeType.filter(overtime => overtime.id === item.overtimeType)
        this.formData.OvertimeType = overTime[0]
      }
      this.formData.salaryComponentType = SalaryComponent[0].name || ''
      this.formData.markup = item.markup
    },
    approve () {
      this.isLoading = true
      this.item.action = 'Approve'
      this.item.status = 1
      this.item.comments = 'Valid'
      this.$store
        .dispatch(APPROVE_SALARY, this.item)
        .then((result) => {
          this.showAlertMessage('Approve Salary successful', 'success')
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.showAlertMessage('Approve Salary failed', 'error')
        }).finally(() => {
          this.dialog = false
          this.isLoading = false
        })
    },
    openConfiModal (item) {
      this.item = item
      this.dialog = true
    },
    reject (item) {
      item.action = 'Rejected'
      item.status = 2
      this.$store
        .dispatch(APPROVE_SALARY, item)
        .then((result) => {
          this.showAlertMessage('Reject Salary successful', 'success')
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.showAlertMessage('Reject Salary failed', 'error')
        })
    },
    cancel (modal) {
      if (modal === 'editModal') {
        this.$refs.editModal.closeModal()
      }
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.closeModal()
      }
    }
  },
  mounted () {
    this.$store
      .dispatch(FETCH_PENDING_SALARY)
      .then(() => {})
      .catch((error) => {
        if (error) {
          this.showAlertMessage('Unable to display pending Salary components', 'error')
        }
      })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
